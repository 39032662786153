import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

import ImageMeta from "../components/ImageMeta"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Video from "../components/Video"

const AcademicAdvantage = () => {
  let language = "en"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          lang={language}
          title={`Virtual Academic Advantage - Fresno Oral Maxillofacial Surgery & Dental Implant Center`}
          description="Fresno Oral Maxillofacial Surgery & Dental Implant Center is proud to announce its 2021 Virtual Academic Advantage."
        />

        <div className="academic-advantage-program">
          <div className="edu-hero">
            <img
              className="hero-bg"
              src="https://res.cloudinary.com/nuvolum/image/upload/v1615826776/Programs/edu-banner.jpg"
              alt="Academic Advantage banner"
            />
          </div>

          <div className="advantage-body">
            <div className="advantage-content">
              <h1 className="content-heading">
                ONLINE HEALTHCARE SYMPOSIUM RESOURCES!
              </h1>
              <p>
                Fresno Oral Maxillofacial Surgery & Dental Implant Center was
                proud to welcome students to our 2021 Virtual Academic Advantage
                event on May 15. Each of our medical speakers gave an overview
                of the path they took to become the healthcare professional they
                are today from high school to college, residency, and even
                becoming a business owner. We also had a counselor provide
                valuable information and practice next steps that students could
                start taking now to prepare for their future career. We also
                gave one student a $1,000 scholarship, two others received Apple
                HomePod Minis, and one student received a Facebook Portal!
              </p>

              <p>
                The resources provided on this page are designed to help
                students continue on their path to becoming a medical
                professional. The entire presentation is available in the video
                below for students to watch or to view for the first time if
                they were unable to join us live. We have also provided the
                presentation slides and links to learn more about local
                colleges, universities, community organizations, and nonprofits.
              </p>

              <section className="body-section" style={{ marginTop: 0 }}>
                <div className="columns">
                  <div className="column">
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId="FOMS/DEV/aa-thumb"
                      data-src="https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/FOMS/DEV/aa-thumb"
                      responsive
                    />
                    <div style={{ marginTop: "30px" }}>
                      <TheaterVideo
                        videoUrl="https://youtu.be/pMDSy_0fcZ8"
                        controls
                        playing
                        hasWatchVideo
                        overVideo={true}
                        buttonClass="contained"
                        sizzleButton
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="advantage-speakers">
              <div className="line"></div>
              <div>
                <h3 className="content-heading">FEATURED SPEAKERS:</h3>

                <p>
                  <a
                    className="accent-blue"
                    href="/ardavan-kheradpir-dmd-md-fresno-ca"
                    title="Dr. Ardavan Kheradpir"
                  >
                    Ardavan Kheradpir, DMD, MD
                  </a>
                </p>
                <p className="accent-blue">
                  Oral and Maxillofacial Surgeon at Fresno Oral Maxillofacial
                  Surgery & Dental Implant Center
                </p>

                <p className="accent-blue bold">LeeAnn Cunningham, RN</p>
                <p className="accent-blue">
                  Registered Nurse at{" "}
                  <a
                    className="accent-blue"
                    style={{ fontWeight: "unset" }}
                    href="https://www.communitymedical.org/homehospitals-facilities/community-regional-medical-center"
                    target="_blank"
                    title="Community Regional Medical Center UCSF"
                  >
                    Community Regional Medical Center/UCSF Fresno Oral and
                    Maxillofacial Care Center
                  </a>
                </p>

                <p className="accent-blue bold">
                  <a
                    className="accent-blue"
                    href="https://www.samc.com/graduate-medical-education/programs/family-medicine/faculty"
                    target="_blank"
                    title="Dr. Michael Moya"
                  >
                    Michael Moya, MD
                  </a>
                </p>
                <p className="accent-blue">
                  Associate Program Director - SAMCFM Residency at Saint Agnes
                  Medical Center
                </p>

                <p className="accent-blue bold">
                  <a
                    className="accent-blue"
                    href="https://stores.healthmart.com/sanjoaquindrug/our_pharmacy.aspx"
                    target="_blank"
                    title="Katherine Bass, PharmD"
                  >
                    Katherine Bass, PharmD
                  </a>
                </p>
                <p className="accent-blue">
                  Pharmacist and Owner of San Joaquin Drug Incorporated
                </p>

                <p className="accent-blue bold">
                  <a
                    className="accent-blue"
                    href="https://ccr.fresnounified.org/staff-directory/#meet-the-college-career-readiness-staff"
                    target="_blank"
                    title="Nancy Wirtago"
                  >
                    Nancy Witrado
                  </a>
                </p>
                <p className="accent-blue">
                  Director of Counseling & Guidance, College & Career Readiness
                  at Fresno Unified School District
                </p>
              </div>
              <div className="line"></div>
            </div>

            <div
              className="advantage-resources"
              style={{ gridColumn: "span 2" }}
            >
              <p className="content-heading">RESOURCES</p>
              <br />
              <ul className="resource-list">
                <p>
                  <a
                    target="_blank"
                    href="https://www.beautifyfresno.org"
                    title="Beautify Fresno"
                  >
                    Beautify Fresno
                  </a>
                </p>
                <ul>
                  <li>
                    Contact for more information:{" "}
                    <a
                      href="mailto:info@beautifyfresno.org"
                      target="blank"
                      title="e-mail Beautify Fresno"
                    >
                      info@beautifyfresno.org
                    </a>{" "}
                    or{" "}
                    <a
                      href="https://www.beautifyfresno.org/contact-us/"
                      target="_blank"
                      title="contact beautify Fresno"
                    >
                      click here
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.beautifyfresno.org/events/"
                      target="_blank"
                      title="Beautify Fresno Volunteer Oppurtunites"
                    >
                      Upcoming Volunteer Opportunities and Events
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <a
                    href="https://ccfoodbank.org/"
                    target="_blank"
                    title="CC FoodBank"
                  >
                    Central California Food Bank - CalFresh Outreach Program
                  </a>
                </p>
                <ul>
                  <li>
                    {" "}
                    Contact for more information:{" "}
                    <a
                      href="mailto:CalFresh@ccfoodbank.org"
                      target="_blank"
                      title="email CC Foodbank"
                    >
                      CalFresh@ccfoodbank.org
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ccfoodbank.org/enroll-in-calfresh/"
                      target="_blank"
                      title="CalFresh Enrollment"
                    >
                      CalFresh Enrollment
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ccfoodbank.org/get-involved/"
                      target="_blank"
                      title="Get Involved - Volunteer"
                    >
                      Get Involved - Volunteer
                    </a>
                  </li>
                  <li>
                    <a
                      href="/EDU/Central California Food Bank - CalFresh Flyer.pdf"
                      title="CalFresh Flyer"
                      target="_blank"
                    >
                      CalFresh Flyer
                    </a>
                  </li>
                  <li>
                    <a
                      href="/EDU/Central California Food Bank - G2G - Flyer.pdf"
                      title="CalFresh Flyer"
                      target="_blank"
                    >
                      Groceries 2 Go Flyer
                    </a>
                  </li>
                  <li>
                    <a
                      href="/EDU/Central California Food Bank - G2G Satellite Sites (2).pdf"
                      title="CalFresh Flyer"
                      target="_blank"
                    >
                      Groceries 2 Go Satellite Sites Flyer
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <a
                    href="http://sonoma.edu/"
                    target="_blank"
                    title="Sonoma State University"
                  >
                    Sonoma State University
                  </a>
                </p>
                <ul>
                  <li>
                    Admissions Contact:{" "}
                    <a
                      href="mailto:outreach@sonoma.edu"
                      target="_blank"
                      title="email SSU"
                    >
                      outreach@sonoma.edu
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://inquiry.sonoma.edu/inquiryform"
                      title="Request Information from SSU"
                      target="_blank"
                    >
                      Request Information
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://admissions.sonoma.edu/files/sonoma-state-university-virtual-campus-tour"
                      target="_blank"
                      title="Virtual Tour"
                    >
                      Virtual Tour
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <a
                    href="https://www.csustan.edu/"
                    target="_blank"
                    title="CSU Stanislaus"
                  >
                    Stanislaus State
                  </a>
                </p>
                <ul>
                  <li>
                    Admissions Contact: Admission and Outreach Department, (209)
                    667-3070,{" "}
                    <a
                      href="mailto:Outreach_Help_Desk@csustan.edu"
                      title="email Stanislaus State"
                      target="_blank"
                    >
                      outreach_help_desk@csustan.edu
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.csustan.edu/undergrad/contact-us"
                      title="Schedule Appointment Stanislaus Admissions"
                      target="_blank"
                    >
                      Schedule Appointment with Admissions Counselor
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.csustan.edu/undergrad/campus-tours"
                      target="_blank"
                      title="Virtual CSU Stanislaus Tours"
                    >
                      Virtual Tour and Campus Tours
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.csustan.edu/undergrad/viewbook"
                      title="CSU Stanislaus Viewbook"
                      target="_blank"
                    >
                      Viewbook
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <a
                    href="https://pharmacy.pacific.edu/pharmacy"
                    target="_blank"
                    title="Thomas J. Long School of Pharmacy at University of the Pacific"
                  >
                    Thomas J. Long School of Pharmacy at University of the
                    Pacific
                  </a>
                </p>
                <ul>
                  <li>
                    Admissions Contact:{" "}
                    <a
                      href="mailto:pharmacyrecruiting@pacific.edu"
                      title="email pharmacy school at Pacific"
                      target="_blank"
                    >
                      pharmacyrecruiting@pacific.edu
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://pacific.formtitan.com/pharminquiry#/"
                      title="Request Information Pharmacy University of Pacific"
                      target="_blank"
                    >
                      Request Information
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/watch?v=3s9ig4JpLxQ"
                      title="Virtual Tour of University of Pacific"
                      target="_blank"
                    >
                      Virtual Tour
                    </a>
                  </li>
                  <li>
                    <a
                      href="/EDU/UNIVERSITY OF THE PACIFIC - Pre-Pharmacy-Recruiting-Brochure-2020-Digital.pdf"
                      target="_blank"
                      title="Pre-Pharmacy Program"
                    >
                      Pre-Pharmacy Advantage Program
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <a
                    href="https://dentistry.ucsf.edu/"
                    title="UCSF School of Dentistry"
                    target="_blank"
                  >
                    University of California San Francisco, School of Dentistry
                  </a>
                </p>
                <ul>
                  <li>
                    Admissions Contact:{" "}
                    <a
                      href="mailto:Admissions@Dentistry.ucsf.edu"
                      title="email UCSF Dentistry"
                      target="_blank"
                    >
                      admissions@dentistry.ucsf.edu
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://youtu.be/ww7qZJX0QrY"
                      title="UCSF Virtual Tour"
                      target="_blank"
                    >
                      Virtual Tour
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <a
                    href="https://www.usfca.edu/"
                    title="University of San Francisco"
                    target="_blank"
                  >
                    University of San Francisco
                  </a>
                </p>
                <ul>
                  <li>
                    <a
                      href="https://apply.usfca.edu/register/undergraduate-request-info"
                      title="USF Admissions Contact"
                      target="_blank"
                    >
                      Admissions Contact
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.usfca.edu/admission/undergraduate/visit/virtual-tour"
                      title="USF Virtual Tour"
                      target="_blank"
                    >
                      Virtual Tour
                    </a>
                  </li>
                  <li>
                    <a
                      href="/EDU/University of San Francisco - USFCA Brochure 2020.pdf"
                      title="USF Brochure"
                      target="_blank"
                    >
                      5 Facts
                    </a>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default AcademicAdvantage
